import { options } from "./import/options";
// import "./import/sliders";
import 'bootstrap/js/dist/dropdown';
import {
  // embedYoutube,
  // accordionMenu,
  // setFullHeight,
  // fillInput,
  // expander,
  scrollTo,
  // scrollHeader,
  // selector,
  // openTab,
  showMenu,
  // setProgress,
  // scrollHeader,
  // setIntersectionObserver,
  // setAspectRatioVideo,
  // Modal,
  // Timer,
  // customCursor,
} from "./import/helpers";

// import objectFitImages from "object-fit-images";
// import Zooming from "zooming";
// import tippy from "tippy.js";
// import Chart from "chart.js";
// import "magnific-popup";

// Custom plugins
// import "./import/jquery.countup";
// Constants

// Glitchy Grid Lib
// import "./import/glitchy-grid/index";

// function initCharts() {
//   const chartIds = ["#hypotheken", "#mitarbeiter", "#bestand"];

//   chartIds.forEach(el => {
//     if ($(el).length) new Chart($(el), options.charts[el.slice(1)]);
//   });
// }
class App {
  constructor() {
    this.addEventListeners();
    // this.initCharts();
    // embedYoutube();
    // $("[data-popup]").magnificPopup(options.popup);

    // End Loader dev
    // setProgress();
  }
  // initCharts() {
  //   const chartIds = ["#hypotheken", "#mitarbeiter", "#bestand"];
  //   chartIds.forEach(el => {
  //     if ($(el).length) new Chart($(el), options.charts[el.slice(1)]);
  //   });
  // }
  addEventListeners() {
    // $(window).on("scroll", () => {
    //   scrollHeader();
    // });

    $(window).on("load", () => {
      // End Loader prod
      // setIntersectionObserver(this.initCharts, "#sec3");
      console.log("App init\n");
    });

    $(window).on("resize", () => {
      // setFullHeight();
    });

    // Elements events
    // $(".to-top, a[href^='#sec']").on("click", scrollTo);
    $("#nav-toggle").on("click", () => showMenu());
    // $(".accordion__title").on("click", accordionMenu);
  }
}

new App();
